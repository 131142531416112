<template>
  <div class="addDiscount container">
    <el-form
      :model="form"
      style="min-width:1000px;"
      label-width="100px"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="优惠券名称">
        <span>{{ info.coupon_name }}</span>
      </el-form-item>
      <el-form-item label="使用时间">
        <span v-if="info.time_limit == 2"
          >{{ info.time_start }}至{{ info.time_end }}</span
        >
        <span v-if="info.time_limit == 1">领取后{{ info.time_days }}天</span>
      </el-form-item>
      <el-form-item label="优惠条件">
        <!-- <span>满{{info.money}}减{{info.enough}}元</span> -->
        <span v-if="info.coupon_type == 1"
          >满{{ info.money }}减{{ info.enough }}元</span
        >
        <span v-if="info.coupon_type == 2"
          >满{{ info.money }}打{{ info.enough }}折</span
        >
        <span v-if="info.coupon_type == 3"
          >满{{ info.money }}减运费{{ info.enough }}折</span
        >
      </el-form-item>
      <el-form-item label="退还方式">
        <span v-if="info.return_type == 1">不可退回</span>
        <span v-if="info.return_type == 2">取消订单</span>
      </el-form-item>
      <el-form-item label="短信通知">
        <el-radio-group v-model="is_send_msg">
          <el-radio :label="1">发送</el-radio>
          <el-radio :label="0">不发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="每人数量">
        <el-input-number
          v-model="form.num"
          step-strictly
          :step="1"
          :min="1"
          :max="99"
          :controls="false"
          placeholder="请输入数字"
        ></el-input-number>
        <span style="margin-left:10px;color:#999">发放给每个人的数量</span>
      </el-form-item>
      <el-form-item label="发放用户">
        <el-radio-group v-model="form.grant_type" class="radio_group">
          <div>
            <el-radio label="1">全选未发放用户</el-radio>
          </div>
          <div>
            <el-radio label="2">全选所有用户</el-radio>
          </div>
          <div>
            <el-radio label="3">选择以下筛选用户</el-radio>
          </div>
        </el-radio-group>
        <div v-if="form.grant_type == 3">
          <el-button type="primary" @click="stockShow = true"
            >导入用户</el-button
          >
          <div class="transfer flex">
            <div class="left">
              <div class="top bb flex between">
                <div>未选用户</div>
                <span class="s1"
                  >{{ userCheckedLength }}/{{ userList.length }}</span
                >
              </div>
              <div class="body bb">
                <el-input
                  v-model="phone_name"
                  placeholder="输入用户姓名/手机"
                  style="width:150px"
                  @keyup.enter.native="getGrantCouponUserLists"
                ></el-input>
                <div class="list">
                  <div class="box" v-for="item in userList" :key="item.user_id">
                    <p class="flex align-center p1">
                      <el-checkbox v-model="item.checked"></el-checkbox>
                      {{ item.real_name }}{{ item.phone }}
                    </p>
                    <p class="p2 gray">已发放数：{{ item.count }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="center flex column column-center">
              <el-button-group>
                <el-button
                  type="primary"
                  icon="el-icon-arrow-left"
                  :disabled="giveCheckedLength == 0"
                  @click="toLeft"
                ></el-button>
                <el-button
                  type="primary"
                  icon="el-icon-arrow-right"
                  :disabled="userCheckedLength == 0"
                  @click="toRight"
                ></el-button>
              </el-button-group>
            </div>
            <div class="right">
              <div class="top bb flex between">
                <div class="flex align-center">
                  <el-checkbox
                    v-model="giveCheck"
                    @change="setAllGiveListCheck"
                    style="margin-right:5px"
                  ></el-checkbox
                  >已选用户
                </div>
                <span class="s1"
                  >{{ giveCheckedLength }}/{{ giveList.length }}</span
                >
              </div>
              <div class="body bb">
                <el-input
                  v-model="give_phone_name"
                  placeholder="输入用户姓名/手机"
                  style="width:150px"
                  @input="filterGiveList"
                ></el-input>
                <div class="list">
                  <div
                    class="box"
                    v-for="item in giveList"
                    :key="item.user_id"
                    v-show="item.show"
                  >
                    <p class="flex align-center p1">
                      <el-checkbox v-model="item.checked"></el-checkbox>
                      {{ item.real_name }}{{ item.phone }}
                    </p>
                    <p class="p2 gray">已发放数：{{ item.count }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <div class="submit flex center align-center">
        <el-button @click="cancel" size="medium">取消</el-button>
        <el-button type="primary" @click="submit" size="medium">提交</el-button>
      </div>
    </el-form>
    <ChangeUser
      :isFlag="stockShow"
      :coupon_data_id="coupon_data_id"
      @list="Importlist"
      @refreshList="getList"
    ></ChangeUser>
  </div>
</template>

<script>
import ChangeUser from "./ChangeUser.vue";
export default {
  name: "addDiscount",
  data() {
    return {
      coupon_data_id: this.$route.query.coupon_data_id,
      info: {},
      form: { grant_type: "1", num: 1 },
      rules: {
        num: [{ required: true, message: "请输入发放数量", trigger: "blur" }],
      },
      phone_name: "",
      give_phone_name: "",
      userList: [], //查询的人
      giveList: [], //确认发放的人
      giveCheck: false, //确认发放全选
      stockShow: false,
      is_send_msg: 1,
    };
  },
  components: {
    ChangeUser,
  },
  created() {
    this.getCouponInfo();
    this.getGrantCouponUserLists();
  },
  computed: {
    userCheckedLength() {
      let data =
        this.userList.filter((ele) => {
          return ele.checked == true;
        }) || [];
      return data.length || 0;
    },
    giveCheckedLength() {
      let data =
        this.giveList.filter((ele) => {
          return ele.checked == true;
        }) || [];
      return data.length || 0;
    },
  },
  methods: {
    getList() {
      this.stockShow = false;
    },
    cancel() {
      this.$router.go(-1);
    },
    Importlist(list) {
      this.userList = Object.assign(this.userList, list);
      this.toRight();
    },
    getCouponInfo() {
      let data = {
        coupon_data_id: this.coupon_data_id,
      };
      this.axios.post("/store/Shopcoupon/getCouponInfo", data).then((res) => {
        let data = res.data.info;
        let info = res.data.info;
        if (data.time_start) {
          info.time_start = this.dayjs(data.time_start * 1000).format(
            "YYYY-MM-DD"
          );
          info.time_end = this.dayjs(data.time_end * 1000).format("YYYY-MM-DD");
        }
        this.info = info;
      });
    },
    //查询人
    getGrantCouponUserLists() {
      let data = {
        page: 1,
        pageNumber: 100,
        coupon_data_id: this.coupon_data_id,
        phone_name: this.phone_name,
      };
      this.axios
        .post("/store/Shopcoupon/getGrantCouponUserLists", data)
        .then((res) => {
          let list = res.data.list;
          list.forEach((ele) => {
            ele.checked = false;
          });
          this.userList = list;
        });
    },
    toLeft() {
      let giveList = [];
      this.giveList.forEach((ele) => {
        if (ele.checked) {
          let index = this.userList.findIndex((ele2) => {
            return ele2.user_id == ele.user_id;
          });
          if (index == -1) {
            let obj = {
              user_id: ele.user_id,
              real_name: ele.real_name,
              phone: ele.phone,
              count: ele.count,
              checked: false,
            };
            this.userList.unshift(obj);
          }
        } else {
          giveList.push(ele);
        }
      });
      this.giveList = giveList;
    },
    toRight() {
      let userList = [];
      this.userList.forEach((ele) => {
        if (ele.checked) {
          let index = this.giveList.findIndex((ele2) => {
            return ele2.user_id == ele.user_id;
          });
          if (index == -1) {
            let obj = {
              user_id: ele.user_id,
              real_name: ele.real_name,
              phone: ele.phone,
              count: ele.count,
              checked: false,
              show: true,
            };
            this.giveList.push(obj);
          }
        } else {
          userList.push(ele);
        }
      });
      this.userList = userList;
    },
    //筛选发放用户
    filterGiveList() {
      this.giveList.forEach((ele) => {
        let name = ele.real_name;
        if (name.match(this.give_phone_name)) {
          ele.show = true;
        } else {
          ele.show = false;
        }
        if (!this.give_phone_name) {
          ele.show = true;
        }
      });
    },
    //全选发放用户
    setAllGiveListCheck() {
      this.giveList.forEach((ele) => {
        ele.checked = this.giveCheck;
      });
    },
    //提交表单
    submit() {
      if (this.loading) return;
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      this.loading = true;
      let data = {
        coupon_data_id: this.coupon_data_id,
        grant_type: this.form.grant_type,
        num: this.form.num,
        is_send_msg: this.is_send_msg,
      };
      if (data.grant_type == 3 && this.giveList.length == 0) {
        this.$message.error("请选择发放的人");
        return;
      } else if (data.grant_type == 3 && this.giveList.length != 0) {
        let arr = this.giveList.map((ele) => {
          return ele.user_id;
        });
        data.user_ids = arr.toString();
      }
      console.log("data", data);
      this.axios
        .post("/store/Shopcoupon/grantUserCoupon", data)
        .then(() => {
          this.$message.success("发放成功");
          this.loading = false;
          this.$router.push("/discounts/discountsList");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-input-number,
.el-cascader {
  width: 250px;
  margin-right: 5px;
}

.submit {
  margin: 30px 0;
}
.transfer {
  margin-top: 5px;
  .left,
  .right {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    max-height: 100%;
    box-sizing: border-box;
    position: relative;
    .top {
      width: 200px;
      height: 40px;
      padding: 0 15px;
      line-height: 40px;
      background: #f5f7fa;
      margin: 0;
      padding-left: 15px;
      border-bottom: 1px solid #ebeef5;
      box-sizing: border-box;
      color: #000;
      .s1 {
        color: #909399;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .body {
      padding: 15px;
      height: 300px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .p1 {
        .el-checkbox {
          margin-right: 10px;
        }
      }
      .p2 {
        font-size: 12px;
        padding-left: 25px;
      }
    }
  }
  .center {
    width: 120px;
  }
}
.radio_group {
  .el-radio {
    margin-bottom: 15px;
  }
}
</style>
