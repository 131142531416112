<template>
  <!-- 批量盘点 -->
  <el-dialog title="导入数据"
             :visible.sync="dialogdata"
             width="800px"
             style="text-align: left"
             :before-close="close"
             @open="opendialog">
    <div class="uploadbox">
      <el-upload class="avatar-uploader"
                 name="image"
                 :action="uploadUrl"
                 :data="uploadExtraData"
                 :headers="uploadHeaders"
                 accept=".xls,.xlsx"
                 :show-file-list="false"
                 :on-success="handleAvatarSuccess"
                 :before-upload="beforeUpload">
        <el-button plain size="medium"
                   v-if="file_name&&!success">重新选择</el-button>
        <el-button plain size="medium"
                   v-if="!file_name">选择文件</el-button>
      </el-upload>
      <div class="msgbox">
        <el-button type="text"
                   style="font-weight: 600"
                   @click="download" v-if="!file_name">下载库存模板</el-button>
      </div>
      <div class="filebox"
           v-if="file_name">
        <div>
          <span>上传文件：</span>
          <span style="color: #666">{{ file_name }}</span>
        </div>
        <div>
          <span>文件大小：</span>
          <span style="color: #666">{{ file_size }}kb</span>
        </div>
        <div v-loading="loading"  element-loading-text="上传中请耐心等待">
        <div v-if="success_count">
          <span>格式正确条数：</span>
          <span style="color: #666">{{ success_count }}条</span>
        </div>
        <div v-if="fail_count">
          <span>格式错误数据：</span>
          <span style="color: #ff514b">{{ fail_count }}</span>
        </div>
        <el-button plain v-show="success" @click="opendialog" >重新选择</el-button>
        <el-button plain
                   v-if="fail_url"
                   @click="doloadFail">下载未匹配数据</el-button>
        </div>
      </div>
      <div class="dec">
        <p><i class="status-point"></i>请按照导出格式完整填写数据后导入，否则无法导入</p>
        <p><i class="status-point"></i>已导入的历史信息再次导入会进行覆盖</p>
        <p><i class="status-point"></i>错误数据可对照文件行数修改数据后重新导入 </p>
        <p><i class="status-point"></i>导入需要时间，请耐心等待</p>
      </div>
    </div>
    <div style="text-align: right; padding-right: 20px; box-sizing: border-box"
         v-if="!success">
      <el-button type="primary"
                 @click="importGoodsStock"
                 style="width: 92px">确认导入</el-button>
    </div>
    <div style="text-align: right; padding-right: 20px; box-sizing: border-box"
         v-if="success">
      <el-button type="primary"
                 @click="ok"
                 style="width: 92px">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
// import constant from '../../../service/common/constant';
import _ from "lodash";
export default {
  data() {
    return {
      dialogdata: false,
      loading:false,
      uploadExtraData: {
        img_type: 5,
        suffix: '.jpg'
      },
      file_name: '',
      file_size: '',
      success_count: '',
      fail_count: '',
      goods_pic: '',
      drugTypeList: [],
      fail_url: '',
      success: false,
      list:[]
    };
  },
  watch: {
    isFlag(e) {
      this.dialogdata = e;
    }
  },
  computed: {
    uploadUrl() {
      if (process.env.NODE_ENV == 'development') {
        // 本地开发使用 api 前缀
        return '/api/store/upload/uploadFile';
      } else {
        return '/store/upload/uploadFile';
      }
    },
    uploadHeaders: function () {
      let HEADERS = {
        version: '1.0.0',
        platform: 'PC',
        headerToken: '',
        exposedToken: '',
        timestamp: 378234880
      };
      return Object.assign(
        {},
        _.pick(HEADERS, ['version', 'platform']),
        {
          timestamp: Date.now(),
          token: window.utils.storage.getter('headerToken')
        }
      );
    }
  },
  props: ['isFlag','coupon_data_id'],
  created() {
    // this.logisticsList()
  },
  methods: {
    //提交
    close() {
      this.$emit('refreshList');
    },
    ok(){
      this.$emit('list',this.list);
      this.$emit('refreshList');
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，请删除后继续上传`);
    },
    //上传之前
    beforeUpload(file) {
      const isText = file.type === 'application/vnd.ms-excel';
      const isTextComputer =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isText && !isTextComputer) {
        this.$message.error('上传文件只能是Excel文件');
        return false;
      }
      if (!isLt1M) {
        this.$message.error('最大支持1MB的文件!');
        return false;
      }
      return (isText && isLt1M) || (isTextComputer && isLt1M);
    },
    //上传成功
    handleAvatarSuccess(res, file, fileList) {
      if (res.code === 0) {
        this.goods_pic = res.data.pic;
        this.file_name = file.name;
        this.file_size = (file.size / 1024).toFixed(2);
      }
    },
    //下载库存模板
    download() {
      let host =
        document.location.protocol +
        '//' +
        window.location.host +
        '/upload/shop_phone.xls';
      location.href = host;
    },
    //下载未成功数据
    doloadFail() {
      let host =
        document.location.protocol +
        '//' +
        window.location.host +
        this.fail_url;
      location.href = host;
    },
    importGoodsStock() {
      if (!this.file_name){
        this.$message({
          message: '请选择上传文件！',
          type: 'warning'
        });
        return false
      }
      this.loading=true
      let data = {
        phone_pic: this.goods_pic,
        coupon_data_id:this.coupon_data_id
      };
      this.axios
        .post('/store/Shopcoupon/getGrantCouponUserLists', data)
        .then((res) => {
          if (res.code == 0) {
            this.success_count = res.data.list.length;
          res.data.list.forEach(tag=>{
            let obj={
              ...tag,
              checked:true,
            }
            this.list.push(obj)
            })
            console.log(this.list)
            this.fail_count = res.data.notphone;
            this.fail_url = res.data.fail_url;
            this.success = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    //获取剂型
    logisticsList() {
      let data = {
        type: '1,2'
      };
    },
    opendialog() {
      this.file_name = '';
      this.file_size = '';
      this.success_count = '';
      this.fail_count = '';
      this.goods_pic = '';
      this.fail_url = '';
      this.success = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.uploadbox {
  padding: 30px 60px;
  box-sizing: border-box;
}
.dec{
  padding-top: 5px;
  color: rgba(168, 163, 163, 0.6);
  font-size: 13px;
  p{
    list-style: inside;
    padding-bottom: 5px;
    text-indent: -11px;
  }
}
.status-point{
  display: inline-block;
  margin-right: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(168, 163, 163, 0.73);
}
.filebox {
  line-height: 40px;
}
</style>
